import { reducer as toastrReducer } from 'react-redux-toastr'
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { reduxTokenAuthReducer } from 'redux-token-auth'

import * as types from 'actions/actionTypes'
import initialState from 'reducers/initialState'

import { adminPanel } from './adminPanel'
import { ams } from './ams'
import { auth } from './auth'
import { breadCrumbReducer } from './breadCrumbReducer'
import { category } from './category'
import { claim } from './claims'
import { claimsBlacklist } from './claimsBlacklist'
import { claimType } from './claimType'
import { businessUnits, currentCompany, experienceCompanies } from './company'
import { countries } from './countries'
import { department } from './department'
import { dnar } from './dnar'
import { documentSettings } from './documentSettings'
import { dynamicForms } from './dynamicForms'
import { educationDetail } from './educationDetail'
import { error } from './error'
import { events } from './events'
import { feedback } from './feedback'
import { formSubmission } from './formSubmission'
import { haDashboard } from './haDashboard'
import { industry } from './industry'
import { integrationsReducer } from './integrations'
import { item } from './item'
import { itemAllocation } from './itemAllocation'
import { leave } from './leave'
import { leavePolicy } from './leavePolicy'
import { location } from './location'
import { modalReducer } from './modalReducer'
import { nationalityIdentifier } from './nationalityIdentifier'
import { notifications } from './notifications'
import { office } from './office'
import { permission } from './permission'
import { pms } from './pms'
import { experiencePositions } from './position'
import { project } from './project'
import { question } from './question'
import { rank } from './rank'
import { report } from './report'
import { role } from './role'
import { setting } from './setting'
import { sidebar } from './sidebar'
import { subscription } from './subscription'
import { subscriptionReports } from './subscriptionReports'
import { teamMember } from './teamManagement'
import { technology } from './technology'
import { thirdPartyVendors } from './thirdPartyVendor'
import { threadType } from './threadType'
import { ticket } from './ticket'
import { timeTracker } from './timeTracker'
import { title } from './title'
import { university } from './university'
import { user } from './user'
import { userSetting } from './userSettings'
import { vendor } from './vendor'
import { workflow } from './workflow'

const appReducer = combineReducers({
  adminPanel: adminPanel,
  categories: category,
  departments: department,
  dnars: dnar,
  dynamicForms: dynamicForms,
  errors: error,
  feedbacks: feedback,
  form: formReducer,
  formSubmission: formSubmission,
  items: item,
  itemAllocations: itemAllocation,
  leaves: leave,
  leavePolicies: leavePolicy,
  modals: modalReducer,
  offices: office,
  permissions: permission,
  notifications: notifications,
  projects: project,
  reduxTokenAuth: reduxTokenAuthReducer,
  reports: report,
  ranks: rank,
  roles: role,
  sidebar: sidebar,
  technologies: technology,
  industries: industry,
  teamMembers: teamMember,
  universities: university,
  companies: experienceCompanies,
  currentCompany: currentCompany,
  businessUnits: businessUnits,
  positions: experiencePositions,
  locations: location,
  tickets: ticket,
  timeTracker: timeTracker,
  titles: title,
  settings: setting,
  auths: auth,
  toastr: toastrReducer,
  users: user,
  userSettings: userSetting,
  vendors: vendor,
  claims: claim,
  claimTypes: claimType,
  integrations: integrationsReducer,
  claimsBlacklist: claimsBlacklist,
  haDashboard: haDashboard,
  events: events,
  workflow: workflow,
  questions: question,
  subscriptions: subscription,
  subscriptionsReports: subscriptionReports,
  educationDetail: educationDetail,
  threadType: threadType,
  countries: countries,
  nationalityIdentifier: nationalityIdentifier,
  documentSettings: documentSettings,
  ams: ams,
  thirdPartyVendors: thirdPartyVendors,
  pms: pms,
  breadCrumbs: breadCrumbReducer
})

const rootReducer = (state, action) => {
  if (action.type === types.RESET_STATE_TO_INITIAL) {
    state = initialState
  }

  return appReducer(state, action)
}

export default rootReducer
